import React, { useMemo, useState } from "react";
import { groupBy, mapValues, uniqBy } from "lodash";
import { Box, HStack, Text } from "@chakra-ui/layout";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Area,
  ComposedChart,
  Scatter,
  ReferenceLine,
  Legend,
} from "recharts";
import { format, isValid, setSeconds, startOfDay, getTime } from "date-fns/esm";
import { useDisclosure } from "@chakra-ui/hooks";
import { Stat, StatGroup, StatLabel, StatNumber } from "@chakra-ui/stat";
import { ButtonGroup, IconButton } from "@chakra-ui/button";
import Icon from "@chakra-ui/icon";

/* icon imports */
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";

/* local imports */
import NoDataAvailable from "common/NoDataAvailable";
import useTimeWindow from "../hooks/useTimeWindow";
import {
  binMillisecondDateToStdTimeUnits,
  getGroupByLabel,
  getPeriodVal,
  isValidNumber,
  timeUnit,
  withinBounds,
} from "../utils/temporal";
import SessionListModal from "./SessionListModal";

const TimeInFlowGraph = ({ data, filterWeeks }) => {
  const chartUnit = timeUnit(filterWeeks);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedPoint, setSelectedPoint] = useState(null);

  const { windowShift, windowStart, windowEnd, shiftLeft, shiftRight } =
    useTimeWindow({
      weeks: filterWeeks,
    });

  const sessionData = useMemo(() => {
    if (!data) return { data: [] };

    let cleaned = data // TODO: Figure out why this additional map was added
      .map((dp) =>
        dp.value
          ? dp
          : {
              ...dp,
              value: null,
            }
      )
      .map(binMillisecondDateToStdTimeUnits)
      .filter(isValidNumber);

    if (filterWeeks) {
      cleaned = cleaned.filter(withinBounds(windowStart, windowEnd));
    }

    const getGroupedByTimeUnit = groupBy(cleaned, chartUnit);

    // Calculate avg and set avg to all instances on same timeUnit
    mapValues(getGroupedByTimeUnit, (sessions) => {
      const calculatedAverage = Math.round(
        sessions.reduce((acc, current) => (acc += current.value), 0) /
          sessions.length
      );

      sessions.forEach((session) => (session.average = calculatedAverage));
    });

    return {
      raw: cleaned,
      data: uniqBy(cleaned, chartUnit),
    };
  }, [data, filterWeeks, chartUnit, windowStart, windowEnd]);

  const metrics = useMemo(() => {
    if (!sessionData.data.length) return { avg: 0, total: 0 };

    const total = sessionData.data.reduce(
      (acc, current) => acc + current.value,
      0
    );
    const avg = total / sessionData.data.length;
    return {
      avg,
      total,
    };
  }, [sessionData]);

  const handlePointClick = (evt) => {
    if (!evt) return;
    const { activePayload } = evt;
    const [{ payload }] = activePayload;

    setSelectedPoint(payload);
    onOpen();
  };

  return (
    <>
      <StatGroup px="8">
        <Stat>
          <StatNumber fontSize="4xl">{hourMinSec(metrics.avg) ?? 0}</StatNumber>
          <StatLabel mt="-3">Avg. Time in Flow</StatLabel>
        </Stat>
        <Stat>
          <StatNumber fontSize="4xl">
            {hourMinSec(metrics.total) ?? 0}
          </StatNumber>
          <StatLabel mt="-3">Total Time in Flow</StatLabel>
        </Stat>
      </StatGroup>
      <Box height="sm" width="full" mt="4" overflow="hidden">
        <Chart
          data={sessionData.data}
          filterWeeks={filterWeeks}
          windowStart={windowStart}
          windowEnd={windowEnd}
          metrics={metrics}
          handlePointClick={handlePointClick}
        />
      </Box>
      {filterWeeks ? (
        <HStack mt="4" justifyContent="center" spacing={2}>
          <ButtonGroup>
            <IconButton
              onClick={shiftLeft}
              aria-label="Week before"
              size="sm"
              variant="outline"
              colorScheme="twitter"
              icon={<Icon as={MdKeyboardArrowLeft} />}
            />
            <IconButton
              onClick={shiftRight}
              disabled={!windowShift}
              aria-label="Week after"
              size="sm"
              variant="outline"
              colorScheme="twitter"
              icon={<Icon as={MdKeyboardArrowRight} />}
            />
          </ButtonGroup>
        </HStack>
      ) : null}
      <SessionListModal
        title={`${getGroupByLabel(filterWeeks)} — ${getPeriodVal(
          filterWeeks,
          new Date(selectedPoint?.fullDate ?? null)
        )}`}
        isOpen={isOpen}
        onClose={onClose}
        sessions={
          selectedPoint
            ? sessionData.raw.filter(
                (point) =>
                  point[chartUnit] === selectedPoint[chartUnit] &&
                  point.value !== null
              )
            : []
        }
      />
    </>
  );
};

export default TimeInFlowGraph;

export const hourMinSec = (secs) => {
  if (!secs) return null;
  const tmpDate = startOfDay(new Date());
  const date = setSeconds(tmpDate, secs);
  const hour = date.getHours();
  const hasHour = !!hour;

  const strFormat = hasHour ? "H:mm:ss" : "m:ss";
  return format(date, strFormat);
};

const Chart = ({
  data,
  filterWeeks,
  windowStart,
  windowEnd,
  metrics,
  handlePointClick,
}) => {
  if (!data.length) {
    <NoDataAvailable>
      {!!filterWeeks && (
        <Text color="gray.600" fontWeight="bold">
          {format(windowStart, "MMM do, yyyy")} —{" "}
          {format(windowEnd, "MMM do, yyyy")}
        </Text>
      )}
    </NoDataAvailable>;
  }

  return (
    <ResponsiveContainer>
      <ComposedChart
        onClick={handlePointClick}
        data={data}
        margin={{
          top: 20,
          right: 80,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" horizontal={false} />
        <XAxis
          dataKey="fullDate"
          allowDuplicatedCategory={false}
          tickFormatter={(val) =>
            val && isValid(new Date(val))
              ? format(
                  new Date(val),
                  `MMM do${filterWeeks > 4 ? ", yyyy" : ""}`
                )
              : format(new Date(), `MMM do${filterWeeks > 4 ? ", yyyy" : ""}`)
          }
          type="number"
          domain={[getTime(windowStart), getTime(windowEnd)]}
        />
        <YAxis
          axisLine={false}
          label={{ value: "Time", angle: -90, position: "insideLeft" }}
          tickFormatter={(val) =>
            val && isFinite(val)
              ? format(new Date(1000 * Math.round(val)), "m:ss")
              : "0:00"
          }
        />
        <Tooltip
          labelStyle={{ color: "#1a202c", fontWeight: 600 }}
          cursor={{ strokeDasharray: "2,2", color: "#4A5568" }}
          formatter={(val) =>
            val ? format(new Date(1000 * Math.round(val)), "m:ss") : "0:00"
          }
          labelFormatter={(val) =>
            val && isValid(new Date(val))
              ? `${getPeriodVal(filterWeeks, new Date(val))}`
              : format(new Date(), "MMM do, yyyy ")
          }
        />
        {filterWeeks && filterWeeks < 12 ? (
          <Scatter
            name="Time in Flow"
            dataKey="average"
            fill="#00a0dc"
            isAnimationActive={false}
          />
        ) : null}
        <Legend wrapperStyle={{ position: "relative" }} />
        <defs>
          <linearGradient id="colorRecent" x1="0" y1="0" x2="0" y2="1">
            <stop offset="1%" stopColor="#0083ff" stopOpacity={0.3} />
            <stop offset="70%" stopColor="#0083ff" stopOpacity={0} />
          </linearGradient>
        </defs>
        <ReferenceLine
          y={metrics.avg}
          position="end"
          stroke="red"
          strokeDasharray="6 3"
        />
        <Area
          animationDuration={500}
          fill="url(#colorRecent)"
          fillOpacity={1}
          strokeWidth={2}
          type="basis"
          name={`${getGroupByLabel(filterWeeks)}'s Average`}
          dataKey="average"
          stroke="#00a0dc"
          dot={false}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
