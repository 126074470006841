import React, { createContext, useEffect, useReducer } from "react";
import { Auth } from "@aws-amplify/auth";
import jwtDecode from "jwt-decode";

/* local imports */
import { ContextFactory } from "./ContextFactory";
import { parseRole } from "./authHelpers";
import { Hub } from "aws-amplify";

const AuthContext = createContext();
const AuthUpdateContext = createContext();

const INITIAL_STATE = {
  isAuthenticated: false,
  user: {},
};

const loadFromStorage = () => {
  const keys = Object.keys(localStorage);
  const accessTokenKey = keys.find(
    (k) =>
      k.includes("CognitoIdentityServiceProvider") && k.includes("accessToken")
  );
  const userKey = keys.find(
    (k) =>
      k.includes("CognitoIdentityServiceProvider") && k.includes("userData")
  );

  let user = null;
  try {
    const loadUser = localStorage.getItem(userKey);
    const loadToken = localStorage.getItem(accessTokenKey);

    const tokenBody = jwtDecode(loadToken);

    if (loadUser === null) {
      user = null;
    }

    const userObj = JSON.parse(loadUser);
    const firstName =
      (userObj.UserAttributes || []).find((attr) => attr.Name === "given_name")
        ?.Value || "";
    const lastName =
      (userObj.UserAttributes || []).find((attr) => attr.Name === "family_name")
        ?.Value || "";

    user = {
      id: userObj.Username,
      name: `${firstName} ${lastName}`.trim(),
      firstName,
      lastName,
      email: (userObj.UserAttributes || []).find(
        (attr) => attr.Name === "email"
      )?.Value,
      role: parseRole(tokenBody["cognito:groups"]),
    };
  } catch (err) {
    console.error(
      `[Load from Storage]: Failed to get user attributes from LocalStorage`
    );
  }
  return { isAuthenticated: !!accessTokenKey, user: user || {} };
};

const AuthReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN": {
      return { ...state, ...action.payload };
    }
    case "LOGOUT": {
      return INITIAL_STATE;
    }
    case "SYNC_STORAGE": {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

const AuthProvider = ({ children }) => {
  const [store, dispatch] = useReducer(AuthReducer, {
    ...INITIAL_STATE,
    ...loadFromStorage(),
  });

  const getUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        const userData = {
          isAuthenticated: true,
          user: {
            id: user.username,
            name: `${user.attributes.given_name || ""} ${
              user.attributes.family_name || ""
            }`.trim(),
            firstName: user.attributes.given_name,
            lastName: user.attributes.family_name || "",
            email: user.attributes.email,
            role: parseRole(
              user.signInUserSession.accessToken.payload["cognito:groups"]
            ),
          },
        };
        dispatch({ type: "LOGIN", payload: userData });
      }
    } catch (error) {
      console.error(`An error occurred when attempting to fetch User`, error);
    }
  };

  useEffect(() => {
    dispatch({ type: "SYNC_STORAGE", payload: loadFromStorage() });
  }, []);

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    const listener = Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
          console.info("User signed in");
          getUser();
          break;
        case "signOut":
          console.info("User signed out");
          dispatch({ type: "LOGOUT" });
          break;
        case "tokenRefresh":
          console.info("Token refreshed");
          getUser();
          break;
        default:
          break;
      }
    });

    return () => Hub.remove("auth", listener);
  }, []);

  return (
    <AuthContext.Provider value={store}>
      <AuthUpdateContext.Provider value={dispatch}>
        {children}
      </AuthUpdateContext.Provider>
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuthContext = ContextFactory("Auth", AuthContext);
export const useAuthUpdateContext = ContextFactory(
  "AuthUpdate",
  AuthUpdateContext
);

export const useAuth = () => [useAuthContext(), useAuthUpdateContext()];
