import gql from "graphql-tag";

export const ADMIN_GET_USER_ATTRIBUTES = gql`
  mutation ADMIN_GET_USER_ATTRIBUTES($userId: ID!) {
    adminGetUser(userId: $userId) {
      email
      given_name
      nickname
      preferred_username
      birthdate
    }
  }
`;
export const ADMIN_LIST_USER_ROLES = gql`
  query ADMIN_LIST_USER_ROLES($userId: ID!) {
    adminListUserGroups(userId: $userId) {
      roles
    }
  }
`;
export const GET_USER = gql`
  query GET_USER($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      email
      preferredUsername
      createdAt
      streak
      lastSessionDate
      resonantFrequency
      sessionsCompleted
      bestStreak
    }
  }
`;

export const GET_USER_SETTINGS = gql`
  query GET_USER($id: ID!) {
    getUser(id: $id) {
      id
      badgeCollection
      totalRewardsAmount
      totalRedeemedAmount
      subscriptionInfo {
        id
        subscriptionProductId
        subscriptionProduct {
          subscriptionPlan
          subscriptionType
          subscriptionMethod
          subscriptionProductType
          active
          duration
          timeUnits
          price
        }
        subscriptionPlan
        subscriptionType
        subscriptionMethod
        subscriptionDate
        expirationDate
        stripeSubscriptionInfo {
          stripeCustomerId
          stripeSubscriptionId
        }
        appleSubscriptionInfo {
          appleTransactionId
          appleReceipt
          expirationDate
        }
        appleTransactionId
        stripeCustomerId
        introPrice
        grossMRR
        netMRR
      }
      feedbacks {
        items {
          id
          rating
          createdAt
          comment
        }
      }
      address {
        address1
        address2
        city
        state
        zip
        country
      }
    }
  }
`;

export const GET_USER_PICTURE = gql`
  query GET_USER($id: ID!) {
    getUser(id: $id) {
      id
      profilePicture {
        bucket
        region
        key
      }
    }
  }
`;

export const GET_USER_SHIPMENTS = gql`
  query GET_USER($id: ID!) {
    getUser(id: $id) {
      id
      shipments {
        items {
          id
          status
          shipstationInfo
        }
      }
    }
  }
`;

export const GET_USER_SESSIONS = gql`
  query GetUserSessions($id: ID!, $limit: Int, $nextToken: String) {
    getUser(id: $id) {
      id
      sessions(limit: $limit, nextToken: $nextToken, sortDirection: DESC) {
        items {
          id
          worldId
          sessionNumber
          timeInFlow
          data
          createdAt
          survey
          preComment
          postComment
        }
        nextToken
      }
    }
  }
`;

export const LIST_ALL_COACH_USER_MESSAGES = gql`
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        recipientId
        conversationId
        createdAt
        updatedAt
        readAt
        body
      }
      nextToken
    }
  }
`;
export const LIST_ALL_USER_MESSAGES = gql`
  query ListAllUserMessages($payload: UserMessagingInput) {
    listAllUserMessages(payload: $payload) {
      result
      error
    }
  }
`;

export const LIST_ALL_MESSAGE_PROVIDER_NUMBERS = gql`
  query ListAllMessageProviderNumbers($payload: UserMessagingInput) {
    listMessageProviderPhoneNumbers(payload: $payload) {
      result
      error
    }
  }
`;

export const GET_SESSION_INFO = gql`
  query GET_SESSION($id: ID!) {
    getSession(id: $id) {
      id
      worldId
      sessionNumber
      timeInFlow
      data
      createdAt
      survey
      preComment
      postComment
    }
  }
`;

export const GET_USER_LIST = gql`
  query LIST_USERS(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        preferredUsername
        email
        firstName
        lastName
        mobile
        createdAt
        isFlowlyTeam
        sessionsCompleted
        lastSessionDate
        streak
        bestStreak
        badgeCollection
        hasKit
        updatedAt
      }
      nextToken
    }
  }
`;

export const SEARCH_USER_BY_EMAIL = gql`
  query UsersByEmail(
    $email: String!
    # $sortDirection: ModelSortDirection
    # $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByEmail(
      email: $email
      # sortDirection: $sortDirection
      # filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        mobile
      }
      nextToken
    }
  }
`;

export const SEARCH_USER_BY_USERNAME = gql`
  query UsersByUsername(
    $preferredUsername: String!
    # $sortDirection: ModelSortDirection
    # $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByUsername(
      preferredUsername: $preferredUsername
      # sortDirection: $sortDirection
      # filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        mobile
      }
      nextToken
    }
  }
`;

export const GET_GUIDE = gql`
  query GetGuide($id: ID!) {
    getGuide(id: $id) {
      id
      organizationName
      linkedUsers {
        items {
          id
          user {
            id
            firstName
            email
            sessionsCompleted
          }
        }
      }
      invitations {
        items {
          id
          userFirstName
          userEmail
          lastSentDate
          accepted
          attempts
        }
      }
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
        email
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_COACH = gql`
  query GetCoach($id: ID!) {
    getCoach(id: $id) {
      id
    }
  }
`;

export const GET_WORLDS = gql`
  query ListWorlds(
    $filter: ModelWorldFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorlds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        numSessions
        firstGradientColor
        secondGradientColor
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const GET_SHIPMENTS = gql`
  query ListShipments(
    $filter: ModelShipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShipments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        createdAt
        status
        createdBy
        shipstationInfo
        shipstationOrderId
        shipstationOrderNumber
        shipstationOrderKey
        recipientAddress {
          address1
          address2
          city
          state
          zip
          country
        }
        recipientEmail
        items {
          name
          sku
          weight
        }
        storeId
        carrierCode
        serviceCode
        packageCode
        confirmation
        carrierName
        serviceName
        packageName
        confirmationName
        shouldCreateOrder
        # updatedAt
        user {
          id
          firstName
          lastName
          email
        }
      }
      nextToken
    }
  }
`;
export const GET_SHIPMENT = gql`
  query GetShipment($id: ID!) {
    getShipment(id: $id) {
      id
      userId
      createdAt
      status
      createdBy
      shipstationInfo
      # updatedAt
      user {
        firstName
        lastName
        email
      }
    }
  }
`;
export const GET_EVENT = gql`
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      title
      description
      joinLink
      signupLink
      startDate
      duration
      type
      imageURL
      permissionTier
      createdAt
      updatedAt
    }
  }
`;

export const GET_EVENTS = gql`
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        joinLink
        signupLink
        startDate
        duration
        type
        imageURL
        permissionTier
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const GET_SUBSCRIPTION_PRODUCTS = gql`
  query ListSubscriptionProducts(
    $filter: ModelSubscriptionProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptionProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        subscriptionMethod
        subscriptionType
        subscriptionPlan
        subscriptionProductType
        title
        description
        numDependents
        active
        initialPrice
        price
        duration
        timeUnits
        createdAt
        updatedAt
        kitPrice
      }
      nextToken
    }
  }
`;
export const GET_SUBSCRIPTION_PRODUCT_BY_ID = gql`
  query GetSubscriptionProduct($id: ID!) {
    getSubscriptionProduct(id: $id) {
      id
      title
      subscriptionMethod
      subscriptionType
      subscriptionPlan
      subscriptionProductType
      trialMonths
      active
      noKit
      initialPrice
      price
      duration
      timeUnits
      createdAt
      updatedAt
      journeySubscriptionProduct {
        id
        title
        description
        subscriptionMethod
        subscriptionType
        subscriptionPlan
        subscriptionProductType
        trialMonths
        active
        initialPrice
        price
        duration
        timeUnits
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_JOURNEY_BY_ID = gql`
  query GetJourney($id: ID!) {
    getJourney(id: $id) {
      id
      title
      description
      isActive
      monthsOfAccess
      featuresConfiguration
      subscriptionProducts {
        items {
          id
          title
          description
          subscriptionMethod
          subscriptionType
          subscriptionPlan
          subscriptionProductType
          trialMonths
          active
          noKit
          initialPrice
          price
          duration
          timeUnits
          createdAt
          updatedAt
          journeySubscriptionProduct {
            id
            title
            description
            subscriptionMethod
            subscriptionType
            subscriptionPlan
            subscriptionProductType
            active
            initialPrice
            price
            trialMonths
            duration
            timeUnits
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

export const LIST_JOURNEYS = gql`
  query ListJourneys(
    $filter: ModelJourneyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJourneys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
      }
    }
  }
`;

export const GET_USERJOURNEYRECORDS_BY_USERID = gql`
  query GET_USERJOURNEYRECORDS($id: ID!) {
    getUser(id: $id) {
      id
      journies {
        items {
          id
          mapOrder
          currentExperiences
          journey {
            id
            title
            description
            hasStrictWorldProgression
            worlds {
              items {
                worldConfiguration {
                  id
                  slug
                  type
                  name
                  numSessions
                  firstGradientColor
                  secondGradientColor
                  order
                  createdAt
                  updatedAt
                }
              }
            }
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const CHECK_ORGANIZATION_SLUG = gql`
  query CHECK_ORG_SLUG($urlSlug: String!) {
    checkOrganizationSlug(urlSlug: $urlSlug) {
      id
      orgName
      subscriptionDescription
      requiresKit
      specialGroup
      offerExpirationDate
      error
      partnerDealConfiguration {
        header
        subHeader
        subscriptionDeals {
          subscriptionProductId
          subscriptionPlan
          subscriptionType
          subscrpitionMethod
          promoCode
          dealDescription
          dealPrice
        }
      }
    }
  }
`;

export const GET_GIFTCARD_BY_CODE = gql`
  query GetGiftCard($id: ID!) {
    getGiftCard(id: $id) {
      id
      organizationId
      redeemed
    }
  }
`;

export const GET_COACH_LINKED_PATIENTS = gql`
  query getCoachLinkedPatients(
    $coachId: ID!
    $patientFilter: ModelCoachUserLinkFilterInput
    $nextToken: String
  ) {
    getCoach(id: $coachId) {
      id
      headshotKey
      user {
        id
        firstName
        lastName
      }
      patients(filter: $patientFilter, nextToken: $nextToken) {
        items {
          id
          userId
          coachId
          user {
            id
            firstName
            lastName
            email
            sessionsCompleted
            lastSessionDate
            kitStatus
            hasKit
            scheduledSurveys {
              items {
                id
                scheduledDate
                expirationDate
                completedDate
                status
                title
              }
            }
            journies {
              items {
                id
                mapOrder
                currentExperiences
                journey {
                  id
                  title
                }
              }
            }
          }
          messages {
            items {
              id
              userId
              recipientId
              conversationId
              createdAt
              updatedAt
              # readAt
              body
            }
          }
        }
        nextToken
      }
    }
  }
`;
