import React, { useMemo } from "react";
import { useHistory, NavLink } from "react-router-dom";
import {
  Container,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Progress,
  VStack,
  Text,
  HStack,
  Box,
  Icon,
  Tooltip,
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import { createColumnHelper } from "@tanstack/react-table";
import {
  differenceInWeeks,
  formatRelative,
  isFuture,
  format,
  formatDistanceToNow,
  parseISO,
  isPast,
} from "date-fns/esm";
import { groupBy } from "lodash";

/** icon imports */
import { HiDotsHorizontal, HiOutlineTrash } from "react-icons/hi";
import { FaCheckCircle, FaDotCircle } from "react-icons/fa";
import { BiMailSend } from "react-icons/bi";

/** local imports */
import { getCoachLinkedPatients } from "./services";
import { PORTAL_ROOT } from "Routes";
import DataGrid from "common/DataGrid";
import DebouncedInput from "common/DebouncedInput";
import { useAuth } from "utils/AuthContext";
import { beautify, toSentenceCase } from "utils/string";

const columnHelper = createColumnHelper();

const SURVEY_STATUS = {
  SCHEDULED: "SCHEDULED",
  ACTIVE: "ACTIVE",
  COMPLETE: "COMPLETE",
  EXPIRED: "EXPIRED",
};
const SURVEY_STATUS_COLORS = {
  SCHEDULED: "gray.500",
  COMPLETE: "green.500",
  ACTIVE: "yellow.500",
  EXPIRED: "red.500",
};

const getSurveyStatus = ({ status, completedDate, scheduledDate }) => {
  if (status === SURVEY_STATUS.COMPLETE || !!completedDate) {
    return {
      status: SURVEY_STATUS.COMPLETE,
      color: SURVEY_STATUS_COLORS[SURVEY_STATUS.COMPLETE],
    };
  }

  const parsedScheduledDate = parseISO(scheduledDate);
  const today = new Date();

  if (isFuture(parsedScheduledDate)) {
    if (
      status === SURVEY_STATUS.ACTIVE &&
      differenceInWeeks(today, parsedScheduledDate) === 0
    ) {
      return {
        status: SURVEY_STATUS.ACTIVE,
        color: SURVEY_STATUS_COLORS[SURVEY_STATUS.ACTIVE],
      };
    }

    return {
      status: SURVEY_STATUS.SCHEDULED,
      color: SURVEY_STATUS_COLORS[SURVEY_STATUS.SCHEDULED],
    };
  }

  if (
    isPast(parsedScheduledDate) &&
    differenceInWeeks(today, parsedScheduledDate) < 1
  ) {
    return {
      status: SURVEY_STATUS.ACTIVE,
      color: SURVEY_STATUS_COLORS[SURVEY_STATUS.ACTIVE],
    };
  }

  if (differenceInWeeks(today, parsedScheduledDate) >= 1) {
    return {
      status: SURVEY_STATUS.EXPIRED,
      color: SURVEY_STATUS_COLORS[SURVEY_STATUS.EXPIRED],
    };
  }

  return {
    status: SURVEY_STATUS.SCHEDULED,
    color: SURVEY_STATUS_COLORS[SURVEY_STATUS.SCHEDULED],
  };
};

function CoachLinkedPatients() {
  const history = useHistory();

  const [{ user }] = useAuth();

  const handleRowClick = (rowData) =>
    history.push(`${PORTAL_ROOT}/users/${rowData.original.user.id}`);

  const { data: result, isLoading } = useQuery(
    ["CoachUserLinks", user.id],
    () =>
      getCoachLinkedPatients({
        variables: {
          coachId: user.id,
        },
      })
  );

  const totalExpectedSessions = 84;
  const columns = useMemo(
    () => [
      {
        header: "Patient ID",
        accessorKey: "user.id",
        id: "patientId",
      },
      {
        header: "Patient First Name",
        accessorKey: "user.firstName",
        id: "patientFirstName",
      },
      {
        header: "Patient Last Name",
        accessorKey: "user.lastName",
        id: "patientLastName",
      },
      {
        header: "Patient Email",
        accessorKey: "user.email",
        id: "patientEmail",
      },
      columnHelper.display({
        id: "displayName",
        header: "Name",
        cell: ({ row }) => {
          return (
            <VStack
              as={NavLink}
              to={`${PORTAL_ROOT}/users/${row.getValue("patientId")}`}
              align="flex-start"
              spacing={0}
              maxW="xs"
            >
              <Text fontWeight="semibold" color="gray.700">
                {`${row.getValue("patientFirstName")} ${
                  row.getValue("patientLastName") ?? ""
                }`.trim()}
              </Text>
              <Text color="gray.500">{row.getValue("patientEmail")}</Text>
            </VStack>
          );
        },
      }),
      {
        header: "Progression",
        id: "sessionsCompleted",
        accessorFn: (row) => row.user.sessionsCompleted,
        cell: ({ row }) => {
          const journey = (row.original.user?.journies?.items || []).find(
            (item) => item.journey.id === "pain-journey"
          );

          const parsedExperiences = JSON.parse(
            journey?.currentExperiences ?? "{}"
          );

          const mapProgress = journey?.mapOrder ?? [];
          const latestProgress = mapProgress[mapProgress.length - 1];

          const sessionsCompleted = Object.values(parsedExperiences).reduce(
            (acc, val) => acc + val,
            0
          );
          return (
            <>
              {parsedExperiences && latestProgress && (
                <Text fontSize="sm" color="gray.700">
                  {beautify(latestProgress)} session{" "}
                  {parsedExperiences[latestProgress]}
                </Text>
              )}
              <Progress
                maxW="12rem"
                rounded="full"
                hasStripe={true}
                colorScheme="green"
                value={Math.round(
                  ((sessionsCompleted ?? 0) / totalExpectedSessions) * 100
                )}
              />
            </>
          );
        },
      },
      {
        header: "Surveys",
        accessorKey: "user.scheduledSurveys",
        id: "scheduledSurveys",
        cell: ({ row }) => {
          const surveys = row.original.user.scheduledSurveys?.items ?? [];

          const scheduledOnly = surveys.filter(
            (survey) => !!survey.scheduledDate
          );

          const condensedView = scheduledOnly.length > 4;

          if (condensedView) {
            const groupedByStatus = groupBy(
              scheduledOnly,
              (survey) => getSurveyStatus(survey).status
            );

            return (
              <HStack>
                {Object.entries(groupedByStatus).map(
                  ([status, surveys], idx) => {
                    return (
                      <Tooltip
                        hasArrow
                        key={`${row.getValue(
                          "patientId"
                        )}-scheduled-survey-${status}-${idx}`}
                        label={`${surveys.length} ${toSentenceCase(
                          status
                        )} Surveys`}
                      >
                        <HStack as="span" spacing={1}>
                          <Text>{surveys.length}</Text>
                          <Icon
                            boxSize={4}
                            as={
                              status === SURVEY_STATUS.COMPLETE
                                ? FaCheckCircle
                                : FaDotCircle
                            }
                            color={SURVEY_STATUS_COLORS[status]}
                          />
                        </HStack>
                      </Tooltip>
                    );
                  }
                )}
              </HStack>
            );
          }

          return (
            <HStack>
              {scheduledOnly.map((survey, idx) => {
                const { completedDate, scheduledDate, title, status } = survey;

                const today = new Date();

                const surveyDetails = getSurveyStatus({
                  status,
                  completedDate,
                  scheduledDate,
                });
                return (
                  <Tooltip
                    hasArrow
                    key={`${row.getValue(
                      "patientId"
                    )}-scheduled-survey-${scheduledDate}-${idx}`}
                    label={`${
                      title
                        ? title
                        : surveyDetails.status === SURVEY_STATUS.EXPIRED
                        ? "Expired survey"
                        : surveyDetails.status === SURVEY_STATUS.COMPLETE
                        ? "Completed survey"
                        : "Scheduled survey"
                    }, due ${formatRelative(parseISO(scheduledDate), today)}`}
                  >
                    <span>
                      <Icon
                        boxSize={4}
                        as={
                          surveyDetails.status === SURVEY_STATUS.COMPLETE
                            ? FaCheckCircle
                            : FaDotCircle
                        }
                        color={surveyDetails.color}
                      />
                    </span>
                  </Tooltip>
                );
              })}
            </HStack>
          );
        },
      },
      {
        header: "Last session",
        accessorKey: "user.lastSessionDate",
        id: "lastSessionDate",
        cell: ({ row }) => {
          if (!row.getValue("lastSessionDate")) return "—";

          return (
            <>
              <Text>
                {format(
                  new Date(row.getValue("lastSessionDate")),
                  "MMM do, yyyy"
                )}{" "}
              </Text>
              <Text color="gray.500" fontSize="sm">
                {formatDistanceToNow(new Date(row.getValue("lastSessionDate")))}{" "}
                ago
              </Text>
            </>
          );
        },
      },
      columnHelper.display({
        id: "actions",
        cell: ({ row }) => {
          return <RowActionsDropdown row={row} />;
        },
      }),
    ],
    []
  );

  const { table: tableInstance, ...dataGridProps } = DataGrid.useDataGrid({
    columns,
    data: result?.data?.getCoach?.patients?.items ?? [],
    initialState: {
      columnVisibility: {
        patientId: false,
        patientFirstName: false,
        patientLastName: false,
        patientEmail: false,
      },
    },
    enablePagination: true,
    // enableRowSelection: true,
  });

  return (
    <Container
      maxW="container.xl"
      p={0}
      rounded="xl"
      border="1px solid"
      borderColor="gray.200"
    >
      <DataGrid.Composer table={tableInstance}>
        <Header {...dataGridProps} />
        <Box
          w="full"
          borderTop="1px solid"
          borderColor="gray.100"
          overflowX="scroll"
        >
          <DataGrid.Table
            isLoading={isLoading}
            onRowClick={handleRowClick}
            // enableRowSelection={false}
          />
          <DataGrid.Footer pagination />
        </Box>
      </DataGrid.Composer>
    </Container>
  );
}

export default CoachLinkedPatients;

const Header = ({ globalFilter, setGlobalFilter }) => {
  // const { getColumn } = DataGrid.useDataGridContext();

  return (
    <HStack p={3}>
      <DebouncedInput
        initialValue={globalFilter}
        onChange={setGlobalFilter}
        placeholder="Find patient"
      />
      {/* {getColumn("type") && (
      <DataGrid.DropdownFilter 
       column={getColumn("type")}
       title="Type"
       options={EVENT_TYPES}
       />
      )} */}
    </HStack>
  );
};

const RowActionsDropdown = ({ row }) => {
  return (
    <Menu isLazy>
      <MenuButton as={IconButton} icon={<HiDotsHorizontal />} variant="ghost" />
      <MenuList>
        {/* TODO: Handle proper kitStatus once backend implementation is pushed */}
        {/* Initial implementation: If kitStatus is null, show the button */}
        {!row.original?.user?.kitStatus && !row.original?.user?.hasKit && (
          <>
            <MenuItem color="linkedin.600" icon={<BiMailSend />}>
              Send Kit
            </MenuItem>
            <MenuDivider />
          </>
        )}
        <MenuItem as={NavLink} to={`${PORTAL_ROOT}/users/${row.original.id}`}>
          View Profile
        </MenuItem>
        <MenuItem>Send Message</MenuItem>
        <MenuItem>Send Session Reminder</MenuItem>
        <MenuItem>Schedule Session</MenuItem>
        <MenuDivider />
        <MenuItem color="red.400" icon={<HiOutlineTrash />}>
          Remove Patient
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
