import React from "react";
import { Redirect } from "react-router-dom";

/* local imports */
import { useAuth } from "utils/AuthContext";
import { verifyCanAccess } from "utils/authHelpers.js";
import { PORTAL_ROOT } from "Routes";
import getRoleRedirectPath from "utils/getRoleRedirectPath";

const Can = ({ children, role: requiredRole }) => {
  const [{ user }] = useAuth();
  const userRole = user?.role;
  const homePath = getRoleRedirectPath(user?.role);

  const can = verifyCanAccess(userRole, requiredRole);

  if (can) return <>{children}</>;

  return <Redirect to={`${PORTAL_ROOT}${homePath}`} />;
};

export default Can;
