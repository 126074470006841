import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Box,
  Text,
  Flex,
  Link as ChakraLink,
  VStack,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Button,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Image,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/toast";
import { useDisclosure } from "@chakra-ui/hooks";
import { useForm } from "react-hook-form";

/* icon imports */
import { HiEye, HiEyeOff } from "react-icons/hi";

/* local imports */
import { PORTAL_ROOT } from "../../Routes";
import { useAuth } from "utils/AuthContext";
import { registerAccount } from "utils/authHelpers";
import H1 from "common/H1";
import confirmation from "assets/confirmation/confirmation.png";

const Register = () => {
  const [loading, setLoading] = useState(false);

  const [, authDispatch] = useAuth();
  const { isOpen, onToggle } = useDisclosure();
  const history = useHistory();
  const toast = useToast();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const createUserAccount = async (data) => {
    try {
      setLoading(true);

      const { user, error } = await registerAccount({ ...data });

      if (error || !user) {
        throw new Error(error);
      }

      authDispatch({
        type: "LOGIN",
        payload: { isAuthenticated: true, user },
      });

      // TODO: redirect to appropriate dashboard based on role
      history.push(PORTAL_ROOT);
    } catch (e) {
      console.error(e);
      toast({
        title: "An error occurred while creating your account",
        description: e.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      height="calc(100vh - 4rem)"
      bg="linear-gradient(180deg, #ffffff 0px, #e9f2fd 25vh)"
    >
      <Grid gridTemplateColumns="repeat(12, 1fr)" height="full">
        {/* Left */}
        <GridItem colSpan={[12, 12, 12, 6, 6]} pr="5vw" pl="10vw" bg="white">
          <form onSubmit={handleSubmit(createUserAccount)} noValidate>
            <VStack mt="20" alignItems="flex-start" spacing={4}>
              <H1>Join Flowly</H1>
              <FormControl id="fullName">
                <FormLabel
                  fontWeight="semibold"
                  display={["none", "none", "block"]}
                >
                  Full Name
                </FormLabel>
                <Flex
                  spacing="4"
                  alignItems={{ base: "flex-start", md: "center" }}
                  flexDir={{ base: "column", md: "row" }}
                >
                  <FormLabel
                    fontWeight="semibold"
                    display={["block", "block", "none"]}
                  >
                    First Name
                  </FormLabel>

                  <Input
                    name="firstName"
                    type="text"
                    placeholder="First name"
                    data-invalid={errors.firstName}
                    size="lg"
                    {...register("firstName", {
                      required: "First name is required",
                    })}
                  />

                  <FormLabel
                    fontWeight="semibold"
                    display={["block", "block", "none"]}
                    mt="4"
                  >
                    Last Name
                  </FormLabel>
                  <Input
                    name="lastName"
                    type="text"
                    placeholder="Last name"
                    data-invalid={errors.lastName}
                    size="lg"
                    ml={{ base: "0", md: "4" }}
                    {...register("lastName", {
                      required: "Last name is required",
                    })}
                  />
                </Flex>
              </FormControl>
              <FormControl id="birthdate" isInvalid={errors.birthdate}>
                <FormLabel fontWeight="semibold">Date of Birth</FormLabel>
                <Input
                  type="date"
                  name="birthdate"
                  placeholder="Date of Birth"
                  size="lg"
                  color={
                    !watch("birthdate") ? "#A0AEC0" : "rgba(0, 0, 0, 0.92)"
                  }
                  {...register("birthdate", {
                    required: "Birthdate is required",
                  })}
                />
              </FormControl>
              <FormControl id="email" isInvalid={errors.email}>
                <FormLabel fontWeight="semibold">Email address</FormLabel>
                <Input
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder="Username or Email"
                  size="lg"
                  {...register("email", {
                    required: "required",
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: "Enter a valid email address",
                    },
                  })}
                />
                {errors.email && errors.email.message !== "required" && (
                  <Text color="red.500">{errors.email.message}</Text>
                )}
              </FormControl>
              <FormControl id="password" isInvalid={errors.password}>
                <FormLabel fontWeight="semibold">Password</FormLabel>
                <InputGroup>
                  <Input
                    name="password"
                    type={isOpen ? "text" : "password"}
                    autoComplete="current-password"
                    placeholder="Password"
                    size="lg"
                    {...register("password", {
                      required: "required",
                      minLength: {
                        value: 8,
                        message: "Password must be at least 8 characters long",
                      },
                    })}
                  />
                  <InputRightElement top="1">
                    <IconButton
                      bg="transparent !important"
                      variant="ghost"
                      aria-label={isOpen ? "Mask password" : "Reveal password"}
                      icon={isOpen ? <HiEyeOff /> : <HiEye />}
                      onClick={onToggle}
                    />
                  </InputRightElement>
                </InputGroup>
                {errors.password && errors.password.message !== "required" && (
                  <Text mt="1" fontSize="sm" color="red.500">
                    {errors.password.message}
                  </Text>
                )}
              </FormControl>
              <Flex alignItems="center">
                <Button
                  type="submit"
                  variant="solid"
                  bg="#5072ec"
                  colorScheme="blue"
                  rounded="xl"
                  height="100%"
                  isLoading={loading}
                  loadingText="Creating your account..."
                  py="1rem"
                  px="110px"
                >
                  Sign up
                </Button>
              </Flex>
              <ChakraLink as={Link} to="./login" color="blue.500">
                Already have an account? Login here
              </ChakraLink>

              <Text pt="6" color="gray.500">
                By continuing, you agree to Flowly&apos;s{" "}
                <ChakraLink
                  color="blue.500"
                  href="https://www.flowly.world/terms-of-service"
                  isExternal
                >
                  Terms of Service
                </ChakraLink>{" "}
                and{" "}
                <ChakraLink
                  color="blue.500"
                  href="https://www.flowly.world/privacy-policy"
                  isExternal
                >
                  Privacy Policy
                </ChakraLink>
              </Text>
            </VStack>
          </form>
        </GridItem>

        {/* Right */}
        <GridItem
          colSpan={6}
          display={["none", "none", "none", "block"]}
          width="full"
          pt="16"
          pl="5vw"
          pr="10vw"
        >
          <Box display="grid" placeItems="center">
            <Image src={confirmation} maxW="40vw" alt="welcome-to-flowly" />
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Register;
