import React from "react";
import { Route, Switch, Redirect } from "react-router";
import { ChakraProvider, CSSReset } from "@chakra-ui/react";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import { CookiesProvider } from "react-cookie";
import PropTypes from "prop-types";

/* local imports */
import CheckoutNav from "layout/CheckoutNav";
import CoachNav from "layout/CoachNav";
// import EmptyNav from "layout/EmptyNav";
import AppNav from "layout/AppNav";
import theme, { Fonts } from "common/theme";
import AuthProvider, { useAuth } from "utils/AuthContext";

import SelectPlan from "pages/Checkout/Plan";
import PlaceOrder from "pages/Checkout/Checkout";
// import CheckoutLanding from "pages/Checkout/Home";
import CheckoutLanding from "pages/Checkout/Pricing";
import PatientsLanding from "pages/Checkout/PatientCheckout";
import PartnerLanding from "pages/Partnership/PartnerLanding";
import CheckoutConfirmation from "pages/Checkout/Confirmation";

import DownloadApp from "pages/DownloadApp/Downloader";

import RegisterIntroWebinar from "pages/HeroOnboarding/RegisterIntroWebinar";

import UserView from "pages/Users/UserView";
import UserSearch from "pages/Users/UserSearch";
import UserSettings from "pages/Users/UserSettings";
import StripeUserBillingPortal from "pages/Users/StripeUserBillingPortal";

import Register from "pages/Auth/Register";
import LoginGuide from "pages/Auth/LoginGuide";
import CompleteProfile from "pages/Auth/CompleteProfile";
import ConfirmAccount from "pages/Auth/ConfirmAccount";
import ForgotPassword from "pages/Auth/ForgotPassword";

import SessionView from "pages/Sessions/SessionView";

import UserInvitation from "pages/Invitation/UserInvitation";

import GuideDashboard from "pages/Dashboard/GuideDashboard";
import UserDashboard from "pages/Dashboard/UserDashboard";

import AdminRoutes from "pages/Admin/AdminRoutes";

import CoachRoutes from "pages/Coach/CoachRoutes";

import GiftUser from "pages/Gift/GiftUser";
import GiftCheckout from "pages/Gift/components_checkout/GiftCheckout";
import RedeemGift from "pages/Gift/RedeemGift";
import GiftConfirmation from "pages/Gift/components_checkout/GiftCheckout_Confirmation";

import RedeemOrgSubscription from "pages/Organization/RedeemOrgSubscription";
import CustomOrgSubscription from "pages/Organization/CustomOrgSubscription";

import RedeemVoucherSubscription from "pages/Voucher/RedeemVoucherSubscription";

import JourneyLanding from "pages/Journeys/JourneyLanding";
import JourneyConfirmation from "pages/Journeys/JourneyConfirmation";

import QueryParamsController from "utils/QueryParamsController";
import { ROLES } from "utils/authHelpers";
import Can from "common/Can";

export const PORTAL_ROOT = "/app/v1";

const Routes = () => {
  return (
    <Switch>
      <Route path={`${PORTAL_ROOT}/coach`} render={CoachPortal} />
      <Route path={PORTAL_ROOT} render={FlowlyPortal} />
      <Route path="/download" component={DownloadApp} />
      <Route path="/register-webinar" render={OnboardingFlow} />
      <Route path="/voucher" render={VoucherFlow} />
      <Route path="/org" render={OrgFlow} />
      <Route path="/gift" render={GiftFlow} />
      <Route path="/" render={CheckoutFlow} />
      <Route component={CheckoutLanding} />
    </Switch>
  );
};

export default Routes;

const OnboardingFlow = (props) => (
  <ChakraProvider theme={theme}>
    <QueryParamProvider adapter={ReactRouter5Adapter}>
      <Switch>
        <Route path="/" component={RegisterIntroWebinar} />
      </Switch>
    </QueryParamProvider>
  </ChakraProvider>
);

const FlowlyPortal = (props) => (
  <ChakraProvider theme={theme}>
    <AuthProvider>
      <Fonts />
      <CSSReset />
      <AppNav />
      <AppRoutes {...props} basename={PORTAL_ROOT} />
    </AuthProvider>
  </ChakraProvider>
);
const CoachPortal = (props) => (
  <ChakraProvider theme={theme}>
    <AuthProvider>
      <Fonts />
      <CSSReset />
      <CoachNav />
      <CoachRoutes {...props} basename={props.path} />
    </AuthProvider>
  </ChakraProvider>
);

const OrgFlow = (props) => (
  <ChakraProvider theme={theme}>
    <CheckoutNav disableBacklink />
    <OrgRoutes {...props} />
  </ChakraProvider>
);
const VoucherFlow = (props) => (
  <ChakraProvider theme={theme}>
    <CheckoutNav disableBacklink />
    <VoucherRoutes {...props} />
  </ChakraProvider>
);
const GiftFlow = (props) => (
  <ChakraProvider theme={theme}>
    <CheckoutNav />
    <GiftRoutes {...props} />
  </ChakraProvider>
);
const CheckoutFlow = (props) => (
  <QueryParamProvider adapter={ReactRouter5Adapter}>
    <CookiesProvider>
      <CheckoutNav />
      <QueryParamsController />
      <CheckoutRoutes {...props} />
    </CookiesProvider>
  </QueryParamProvider>
);

const CheckoutRoutes = () => (
  <Switch>
    <RouteWithLayout
      layout={CheckoutLayout}
      path="/checkout/:subscriptionType/:subscriptionProductId"
      component={PlaceOrder}
    />
    <RouteWithLayout
      layout={CheckoutLayout}
      path="/confirmation"
      component={CheckoutConfirmation}
    />
    <RouteWithLayout
      layout={CheckoutLayout}
      path="/plan"
      exact
      component={SelectPlan}
    />
    <RouteWithLayout
      layout={CheckoutLayout}
      path="/patients"
      component={PatientsLanding}
    />
    <RouteWithLayout
      layout={CheckoutLayout}
      path="/partner/:partnerId"
      component={PartnerLanding}
    />
    <RouteWithLayout
      layout={CheckoutLayout}
      path="/journey/:journeyId"
      component={JourneyLanding}
    />
    <Route path="/journey-confirmation" component={JourneyConfirmationPage} />
    <Route path="/" component={CheckoutLanding} />
  </Switch>
);

const JourneyConfirmationPage = () => (
  <ChakraProvider theme={theme}>
    <Switch>
      <RouteWithLayout
        layout={CheckoutLayout}
        path="/"
        component={JourneyConfirmation}
      />
    </Switch>
  </ChakraProvider>
);
const OrgRoutes = () => (
  <Switch>
    {/* Leave these routes in case we decide to add Org purchasing through the portal */}
    {/* <Route path="/org/confirmation" exact component={RedeemOrgComplete} /> */}
    {/* <Route path="/org/:plan" exact component={GiftCheckout} /> */}
    {/* <Route path="/org" exact component={RedeemOrgSubscription} /> */}
    <Route
      path="/org/custom/:orgCode"
      exact
      component={CustomOrgSubscription}
    />
    <Route path="/org/redeem" exact component={RedeemOrgSubscription} />
  </Switch>
);
const VoucherRoutes = () => (
  <Switch>
    <Route
      path="/voucher/redeem/:orgSlug"
      exact
      component={RedeemVoucherSubscription}
    />
  </Switch>
);
const GiftRoutes = () => (
  <Switch>
    <Route path="/gift/confirmation" exact component={GiftConfirmation} />
    <Route path="/gift/redeem" exact component={RedeemGift} />
    <Route
      path="/gift/:subscriptionType/:subscriptionProductId"
      exact
      component={GiftCheckout}
    />
    <Route path="/gift" exact component={GiftUser} />
  </Switch>
);
const AppRoutes = ({ basename }) => (
  <Switch>
    <ProtectedRoute
      path={`${basename}/users/:userId/session/:sessionId`}
      component={SessionView}
    />
    <ProtectedRoute
      path={`${basename}/users/:userId/settings`}
      component={UserSettings}
    />
    <ProtectedRoute
      exact
      path={`${basename}/users/billing`}
      component={StripeUserBillingPortal}
    />
    <ProtectedRoute path={`${basename}/users/:userId`} component={UserView} />
    <ProtectedRoute path={`${basename}/users`} exact component={UserSearch} />
    <ProtectedRoute
      authorizationLevel={ROLES.ADMIN}
      path={`${basename}/admin`}
      component={AdminRoutes}
    />
    <ProtectedRoute
      authorizationLevel={ROLES.GUIDE}
      path={`${basename}/guide`}
      exact
      component={GuideDashboard}
    />
    <Route path={`${basename}/invitation`} exact component={UserInvitation} />
    <Route
      path={`${basename}/confirm-account`}
      exact
      component={ConfirmAccount}
    />
    <Route
      path={`${basename}/forgot-password`}
      exact
      component={ForgotPassword}
    />
    <Route path={`${basename}/register`} exact component={Register} />
    <Route
      path={`${basename}/completeProfile`}
      exact
      component={CompleteProfile}
    />
    <Route path={`${basename}/login`} exact component={LoginGuide} />
    <ProtectedRoute path={basename} component={UserDashboard} />
  </Switch>
);

export const ProtectedRoute = ({
  component: Component,
  authorizationLevel = ROLES.USER,
  ...rest
}) => {
  const [{ isAuthenticated }] = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Can role={authorizationLevel}>
            <Component {...props} />
          </Can>
        ) : (
          <Redirect
            to={{
              pathname: `${PORTAL_ROOT}/login`,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const RouteWithLayout = ({ layout: Layout, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

const CheckoutLayout = ({ children }) => {
  return (
    <>
      <main>{children}</main>
    </>
  );
};
