import React from "react";
import { Link, useLocation, useHistory, NavLink } from "react-router-dom";
import {
  Box,
  Container,
  Image,
  Avatar as ChakraAvatar,
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
} from "@chakra-ui/react";
import { Auth } from "aws-amplify";

/* icon imports */
import { HiChevronDown } from "react-icons/hi";

/* asset imports */
import logo from "assets/logo.svg";

/* local imports */
import { PORTAL_ROOT } from "../Routes";
import { client } from "utils/awsConfig.js";

import { useAuth } from "utils/AuthContext";
import getRoleRedirectPath from "utils/getRoleRedirectPath";

const noShowList = [
  "/login",
  "/register",
  "/invitation",
  "/confirm-account",
  "/forgot-password",
  "/completeProfile",
];

const AppNav = () => {
  const history = useHistory();
  const { pathname, state } = useLocation();
  const [{ user }, authDispatch] = useAuth();

  const logout = async () => {
    try {
      await Auth.signOut();

      // clean up artifacts & log evt
      window.analytics.track("logged_out");
      window.analytics.reset();
      await client.resetStore();
      localStorage.clear();

      authDispatch({ type: "LOGOUT" });

      history.push(`${PORTAL_ROOT}/login`, { state });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box height="16" py="3" bg="whiteAlpha.200" shadow="md">
      <Container
        maxW="8xl"
        height="100%"
        justifyContent="space-between"
        display="flex"
        alignItems="center"
      >
        <Box>
          <Link to={`${PORTAL_ROOT}${getRoleRedirectPath(user?.role)}`}>
            <Image src={logo} alt="Flowly Logo" width="32" my="auto" />
          </Link>
        </Box>
        {!noShowList.some((r) => pathname.includes(r)) ? (
          <Box>
            <DropdownMenu
              size="sm"
              id={user?.id}
              name={user?.name ?? "User Name"}
              logout={logout}
            />
          </Box>
        ) : null}
      </Container>
    </Box>
  );
};

export default AppNav;

const DropdownMenu = ({ id, name, logout, ...rest }) => (
  <HStack flexShrink={0} spacing={0}>
    <Menu>
      <MenuButton as={Button} rightIcon={<HiChevronDown />} variant="ghost">
        <HStack alignItems="center">
          <ChakraAvatar
            name={name}
            alt={name}
            size="sm"
            mx="0"
            mr="1"
            {...rest}
          />
          <Text variant="button">{name}</Text>
        </HStack>
      </MenuButton>
      <MenuList>
        <MenuItem as={NavLink} to={`${PORTAL_ROOT}/users/${id}`}>
          View Profile
        </MenuItem>
        <MenuItem onClick={logout}>Sign out</MenuItem>
      </MenuList>
    </Menu>
  </HStack>
);
